import React, { Component } from 'react';

class Hero extends Component {

  render = () => {

    return (
      <div id="Hero">
        <h1>Hello, I'm Alex</h1>
        <h3>Software Engineer and Game Developer</h3>
      </div>
    )
  }
}

export default Hero;